import React, { useState } from "react";
import "./AboutUs.css";
import background from "../../assets/images/blog26.png";
import class1 from "./class.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const founders = [
    {
      name: "Shashank",
      designation: "Founder",
      message:
        "Ever felt like your dreams were stuck in Hinglish traffic? That's what I saw growing up in Bhadohi. Brilliant minds held back by a lack of English fluency. That's why I built Accent Aura - to smash those language barriers and get you cruising towards your goals! We're not about boring grammar drills. We're about building the confidence to own your English and unlock a world of opportunity",
    },
    {
      name: "Navneet",
      designation: "Founder",
      message:
        "Remember those chai discussions with friends in a Mumbai cafe, dreaming of making a difference? That's where Accent Aura began! We believe nailing your English isn't just about fancy vocabulary; it's about unlocking your potential. Our courses are designed for everyone - the student prepping for exams, the working professional seeking a promotion, the entrepreneur with a global vision, and even the homemaker who wants to chat with her NRI cousins with confidence! We meet you where you are and take your English to the next level. Plus, you'll be part of a supportive online community – a virtual chai circle where everyone learns and grows together",
    },
    {
      name: "Chetan",
      designation: "Founder",
      message:
        "Let's be honest, English can feel like that uncle at a wedding who just doesn't get your jokes. At Accent Aura, we get it. We're on a mission to help you ditch the awkward fumbling and speak English with the confidence of a Bollywood hero (minus the unnecessary drama, of course). We're not your average online course. We're a bunch of language ninjas who've combined cutting-edge tech with personalized learning to create a learning experience that's as fun as a Sunday Biryani. Whether you're a visual learner who needs pictures or an auditory learner who thrives on conversations, we've got you covered. Think of us as your English cheerleaders. We'll celebrate your wins, big or small, and be your partner in conquering the language. Join our vibrant community of learners, connect with awesome mentors, and let's rewrite the story of English learning together. With Accent Aura, you're not just learning English; you're unlocking your inner rockstar and getting ready to take the world by storm!",
    },
  ];

  const truncateMessage = (message, wordLimit) => {
    const words = message.split(" ");
    if (words.length <= wordLimit) return message;
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  const [readMoreStates, setReadMoreStates] = useState(
    new Array(founders.length).fill(false)
  );

  const toggleReadMore = (index) => {
    setReadMoreStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <div className="about-us" id="about">
      <div className="banner-container">
        <img src={background} alt="Banner" className="banner-image" />
        <div className="banner-text">
          <div className="banner-text1">
            <h1>
              Unlock Your Potential with Accent Aura: Where{" "}
              <span>English Fluency Meets Indian Ambition</span>
            </h1>
            <p>
              Where English Fluency Meets Indian Ambition: Empowering
              Professionals to Speak Confidently, Lead Globally, and Shape the
              Future
            </p>
          </div>
        </div>
      </div>
      <div className="about-accentaura">
        <div className="about-accentaura-heading">
          <h1>
            About <span className="span">Acent Aura</span>
          </h1>
        </div>
        <div className="about-accentaura-para">
          <p>
            Do you know that feeling when you have a brilliant idea but you
            struggle to put it into words? Or that time when you hesitated to
            speak up in a meeting because you were worried your accent might
            hold you back? Well, we’ve been there too. That’s why we decided to
            create Accent Aura - your partner in conquering the English language
            and boosting your confidence. We are not your typical online
            learning company. We’re here to become your partner in your journey
            and celebrate your achievement throughout the journey. We were born
            out of the shared vision of three friends who observed that the
            language barrier was holding back a lot of the potential of our
            country. We’re here to change the game for online English Lessons.
            Our English classes are specifically designed for Indian
            professionals like yourself. Whether you're just starting out and
            finding your voice, or you're already an advanced speaker looking to
            polish your skills, we've got your back. Our expert instructors
            understand the unique nuances of learning English in the Indian
            context, so you can be sure that the education you receive is not
            only effective but also culturally relevant. Are you ready to
            transform your English skills and open doors to new opportunities?
            Well, your journey starts right here with Accent Aura.
          </p>
        </div>
      </div>
      <div className="mission-and-vision">
        <div className="mission-and-vision-image">
          <img src={class1} alt="class1" className="imageClass" />
        </div>
        <div className="mission-and-vision-text">
          <div className="mission-and-vision-heading">
            <h1>
              Mission and <span className="span">Vision</span>
            </h1>
          </div>
          <ul className="mission-vision-ul">
            <li>
              {" "}
              Empower India's future leaders through accessible, high-quality
              online English communication lessons, fostering confidence and
              fluency in global interactions
            </li>
            <li>
              Build a connected India where professionals can express themselves
              eloquently in English, breaking down barriers and catalyzing
              international collaboration
            </li>
            <li>
              Nurture articulate and compassionate leaders who will make
              significant impacts on the world stage, reshaping India's global
              presence
            </li>
            <li>
              Unlock doors to global opportunities by providing innovative,
              adult-focused English classes that blend language mastery with
              leadership skills
            </li>
            <li>
              Shape a future where Indian professionals stand tall in
              international arenas, driving a transformative journey of personal
              and national growth
            </li>
          </ul>
          <p>
            So why wait? Join Accent Aura today and become part of this
            transformative journey towards mastering English and leadership.
          </p>
        </div>
      </div>
      <div className="founder-section">
        <h2 className="founder-title">
          Message From <span className="highlight">Founders</span>
        </h2>
        <Slider {...settings} className="founder-cards">
          {founders.map((founder, index) => (
            <div className="founder-card" key={index}>
              <div className="first-content">
              <div className="founder-image-container">
                <img
                  src={class1}
                  alt={founder.name}
                  className="founder-image"
                />
              </div>

              </div>
              <div className="second-content">
              <div className="founder-info">
                <h3>{founder.name}</h3>
                <p className="designation">{founder.designation}</p>
                <p>
                  {readMoreStates[index]
                    ? founder.message
                    : truncateMessage(founder.message, 60)}
                  <span
                    onClick={() => toggleReadMore(index)}
                    className="read-more"
                  >
                    {readMoreStates[index] ? " Show Less" : " Read More"}
                  </span>
                </p>
              </div>

              </div>
              
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default AboutUs;
